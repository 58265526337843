<script setup>

</script>


<template>
    <div class="w-full min-h-screen block lg:flex lg:overflow-hidden">
        <!-- Sección izquierda con imagen de fondo -->
        <section class="w-full lg:w-1/2 bg-cover lg:h-screen relative px-12 py-12" style="background-image: url('/images/backgrounds/background_signin.jpg');">
            <div class="w-3/4 m-auto">
                <a href="#">
                    <img src="images/svg/logo_pai_suscripciones.svg" class="w-[180px] mb-20" alt="Pai Suscripciones">
                </a>
                <div class="text-white text-3xl sm:text-5xl font-bold mb-6">Gestor de suscripciones</div>
                <div class="text-white text-lg font-bold">Optimice su plan y ofertas de membresía con flexibilidad y véndalas con una gran experiencia para sus clientes.</div>
            </div>


        </section>


        <!-- Sección derecha -->
        <section class="bg-white w-full lg:w-1/2 flex-grow p-6 lg:p-0">
            <!-- Contenido de la página -->
            <main class="py-4 lg:py-6">
                <slot />
            </main>
        </section>
    </div>
</template>
